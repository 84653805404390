<template>
  <div
    ref="rootElement"
    class="multiview-status-filter"
  >
    <div
      :class="[
        'multiview-status-filter__button',
        { 'multiview-status-filter__button--is-placeholder': isButtonPlaceholder },
      ]"
      tabindex="0"
      @click="toggleMenu"
      @keypress.enter="toggleMenu"
    >
      <div class="multiview-status-filter__button-label">
        <div class="multiview-status-filter__button-label-text">
          {{ buttonLabel }}
        </div>
        <div
          v-if="buttonLabelSuffix"
          class="multiview-status-filter__button-label-suffix"
        >
          {{ buttonLabelSuffix }}
        </div>
      </div>
      <div class="multiview-status-filter__button-icon">
        <Icon
          name="chevron-selector"
        />
      </div>
    </div>
    <div
      v-if="isMenuOpen"
      class="multiview-status-filter__menu"
    >
      <div
        v-for="option in options"
        :key="option"
        :class="[
          'multiview-status-filter__menu-item',
          { 'multiview-status-filter__menu-item--is-active': isOptionSelected(option) },
        ]"
        tabindex="0"
        @click="toggleOption(option)"
        @keypress.enter="toggleOption(option)"
      >
        <div class="multiview-status-filter__menu-item-icon">
          <Icon
            v-if="isOptionSelected(option)"
            name="check"
          />
        </div>
        <div class="multiview-status-filter__menu-item-label">
          {{ getOptionLabel(option) }}
        </div>
      </div>
      <div
        v-if="showBulkAction"
        class="multiview-status-filter__menu-separator"
      />
      <div
        v-if="showBulkAction"
        class="multiview-status-filter__menu-item"
        tabindex="0"
        @click="performBulkAction"
        @keypress.enter="performBulkAction"
      >
        {{ bulkActionLabel }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  pickBy,
  identity,
  isNil,
  isArray,
  includes,
  filter,
} from 'lodash';
import { onClickOutside } from '@vueuse/core';
import Icon from '@/components/common/Icon';

const statusIds = ['N_A', 'PREMATCH', 'LIVE', 'FINISHED'];

const statusesById = {
  N_A: { label: 'N/A' },
  PREMATCH: { label: 'Pregame' },
  LIVE: { label: 'Live' },
  FINISHED: { label: 'Finished' },
  CANCELED: { label: 'CANCELED' },
};

export default {
  components: {
    Icon,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const rootElement = ref(null);
    const isMenuOpen = ref(false);

    const query = computed(() => route.query.status);
    const values = computed(() => {
      if (isNil(query.value)) return null;
      if (isArray(query.value)) return query.value;
      return [query.value];
    });
    const options = computed(() => statusIds);

    const isOptionSelected = (id) => includes(values.value, id);
    const getOptionLabel = (id) => statusesById?.[id]?.label || 'N/A';
    const isButtonPlaceholder = computed(() => !values.value?.length);
    const buttonLabel = computed(() => (isButtonPlaceholder.value ? 'Status' : getOptionLabel(values.value[0])));
    const buttonLabelSuffix = computed(() => (values.value?.length > 1 ? `+${values.value.length - 1}` : ''));

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
    };
    const closeMenu = () => {
      isMenuOpen.value = false;
    };
    const toggleOption = (value) => {
      let updatedValues = [...(values.value ?? [])];
      if (includes(updatedValues, value)) {
        updatedValues = filter(updatedValues, (existingValue) => value !== existingValue);
      } else {
        updatedValues = [...updatedValues, value];
      }
      closeMenu();
      router.replace({
        name: route.name,
        query: pickBy({
          ...route.query,
          status: updatedValues,
        }, identity),
      });
    };

    const showBulkAction = computed(() => !!options.value?.length);
    const bulkActionLabel = computed(() => (values.value?.length ? 'Clear all' : 'Select all'));
    const selectAll = () => {
      closeMenu();
      router.replace({
        name: route.name,
        query: pickBy({
          ...route.query,
          status: options.value,
        }, identity),
      });
    };
    const clearAll = () => {
      const { status, ...queryWithoutStatus } = route.query;
      closeMenu();
      router.replace({
        name: route.name,
        query: pickBy(queryWithoutStatus, identity),
      });
    };
    const performBulkAction = () => {
      if (values.value?.length) {
        clearAll();
      } else {
        selectAll();
      }
    };

    onClickOutside(rootElement, closeMenu);

    return {
      rootElement,
      isMenuOpen,
      values,
      options,
      isButtonPlaceholder,
      buttonLabel,
      buttonLabelSuffix,
      isOptionSelected,
      getOptionLabel,
      toggleMenu,
      toggleOption,
      showBulkAction,
      bulkActionLabel,
      selectAll,
      clearAll,
      performBulkAction,
    };
  },
};
</script>

<style lang="scss">
.multiview-status-filter {
  position: relative;
  cursor: pointer;
  user-select: none;

  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.25rem 0.625rem;
    border-radius: 6px;
    width: 180px;
    min-width: 180px;
    max-width: 180px;
    height: 32px;
    border-radius: var(--Radius-xsm, 4px);
    border: 1px solid var(--neutral-stroke-default-active, #F0F0F0);
    background: var(--neutral-bg-default-resting, #FFF);
    overflow: hidden;

    &:focus {
      box-shadow: 0px 0px 0px 2px #A2BFFF;
      outline: none;
    }

    &-label {
      display: flex;
      overflow: hidden;

      &-text {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &-suffix {
        background-color: #003C3C;
        color: #fff;
        border-radius: 100%;
        margin-left: 4px;
        height: 21px;
        width: 21px;
        aspect-ratio: 1/1;
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-icon {
      margin-left: 0.5rem;
      flex-shrink: 0;

      & > * {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    &--is-placeholder {
      color: #CDCDCD;

      .multiview-status-filter__button-icon {
        fill: #CDCDCD;
      }
    }
  }

  &__menu {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    max-height: 560px;
    overflow-y: auto;
    z-index: 9000;
    display: flex;
    flex-direction: column;
    border-radius: var(--radius-medium, 4px);
    border: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
    background: var(--neutral-bg-default-resting, #FFF);
    box-shadow: 0px 2px 4px 0px rgba(25, 20, 20, 0.08);
    margin-top: 4px;
    padding: 4px 0;

    &-separator {
      background: #F0F0F0;
      height: 1px;
      min-height: 1px;
      max-height: 1px;
      margin: 2px 0;
    }

    &-item {
      display: flex;
      align-items: flex-start;
      padding: 8px;
      gap: 4px;
      background: var(--neutral-bg-default-resting, #FFF);
      overflow: hidden;
      color: var(--neutral-text-default-resting, #191414);
      text-overflow: ellipsis;
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
      white-space: nowrap;
      width: 100%;
      flex: 1;

      &:hover,
      &:focus {
        background-color: #FAFAFA;
        outline: none;
      }

      &-icon {
        border-radius: var(--radius-medium, 4px);
        border: 2px solid var(--neutral-stroke-default-resting, #F0F0F0);
        background: var(--neutral-bg-default-resting, #FFF);
        width: 16px;
        height: 16px;
        stroke: #fff;
      }

      &--is-active {
        .multiview-status-filter__menu-item-icon {
          background: var(--primary-bg-default-resting, #003C3C);
          border-color: #003C3C;
        }
      }
    }
  }
}
</style>
