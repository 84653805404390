<template>
  <div class="markets-container">
    <div
      class="markets-container__placeholder"
      v-for="market in mappedMainMarkets"
      :key="market.marketId"
    >
      <MarketCard
        :market="market"
        :event-id="event.eventId"
        :has-borders="false"
        :has-toggle="false"
        :multi-view="true"
        :is-usa-view="event.isUsaView"
        :sport-id="event.sportId"
        :sport-label="event.sport.sportLabel"
        :competition-id="event.competition.competitionId"
        :home-team="event.competitors[0]"
        :away-team="event.competitors[1]"
        :is-simulated-preview-on="event.showSimulatedMarkets"
      />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import {
  filter, map, find, includes,
} from 'lodash';
import MarketMapper from '@/services/helpers/market-mapper';
import MarketCard from '../../singleview/markets/MarketCard';

export default {
  components: {
    MarketCard,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const markets = computed(() => {
      const storeMarkets = props.event?.showSimulatedMarkets
        ? store.getters.getMultiviewListEventMarketsForSimulation(props.event.eventId)
        : store.getters.getMultiviewListEventMarkets(props.event.eventId);

      if (props.event?.matchState === 'LIVE') {
        return filter(storeMarkets,
          (market) => market.inPlay && market.resultingStatus !== 'RESULTED');
      }
      return storeMarkets;
    });

    const mainMarkets = computed(() => filter(markets.value,
      (market) => market.marketType.params.SELECTIONS === 2
      && (market.marketCode === 'RESULT'
      || ((market.marketCode === 'POINT_HANDICAP' || market.marketCode === 'POINT_OVER_UNDER'
      || market.marketCode === 'GOAL_HANDICAP' || market.marketCode === 'GOAL_OVER_UNDER'
      || market.marketCode === 'RUN_HANDICAP' || market.marketCode === 'RUN_OVER_UNDER')
      && market.marketSummary?.isMainLine))));

    const mainMarketsConstant = [
      {
        label: 'Moneyline',
        partialMarketCode: 'RESULT',
        hasLine: false,
      },
      {
        label: 'Spread',
        partialMarketCode: 'HANDICAP',
        hasLine: true,
      },
      {
        label: 'Total',
        partialMarketCode: 'OVER_UNDER',
        hasLine: true,
      },
    ];

    const mappedMainMarkets = computed(() => map(mainMarketsConstant, (market) => {
      const foundMainMarket = find(mainMarkets.value, (propMarket) => includes(propMarket.marketCode, market.partialMarketCode));
      if (!foundMainMarket) return market;
      return {
        ...market,
        ...foundMainMarket,
        selections: MarketMapper.sortSelections({ ...foundMainMarket, isUSAView: props.event?.isUsaView }),
      };
    }));

    return {
      mappedMainMarkets,
    };
  },
};
</script>

<style lang="scss">
.markets-container {
  flex: 1;
  display: flex;
  width: 100%;
  height: 100%;
  border-right: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);

  &__placeholder {
    flex: 1;
    width: 240px;
    min-width: 240px;
    max-width: 240px;
    border-right: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
  }
}
</style>
