<template>
  <div class="multiview-header">
    <div class="multiview-header__box">
      <StatusFilter />
    </div>
    <div class="multiview-header__box">
      <EventsDropdown />
    </div>
  </div>
</template>

<script>
import { computed, watch, inject } from 'vue';
import { useRoute } from 'vue-router';
import StatusFilter from './StatusFilter';
import EventsDropdown from './EventsDropdown';

export default {
  components: {
    StatusFilter,
    EventsDropdown,
  },
  setup() {
    const route = useRoute();
    const status = computed(() => route.query.status || '');

    const loadEvents = inject('loadEvents');
    watch(
      status,
      (newStatus, oldStatus) => {
        if (route.name !== 'trading-ui-multiview' || newStatus === oldStatus) return;
        loadEvents();
      },
    );
  },
};
</script>

<style lang="scss">
.multiview-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 0;

  &__box {
    display: flex;
    gap: 8px;
  }
}
</style>
