<template>
  <div class="parameters-container">
    <Spinner v-if="isParamsLoading" />
    <div
      v-if="isSimulatingPrices"
      class="simulating-prices-overlay"
    >
      Simulating prices...
    </div>
    <div
      v-if="!isSimulatingPrices && (!globalParams?.length && !activeTabParams.length) && !isParamsLoading"
      class="no-params-root"
    >
      Currently, there are no parameters.
    </div>
    <div
      v-if="!isSimulatingPrices && (globalParams?.length || activeTabParams.length) && !isParamsLoading"
      class="params-content"
    >
      <div
        class="params-header-tabs"
      >
        <div
          v-for="tab in parametersTabs"
          :key="tab.value"
          :class="[
            'params-header-tabs-item',
            { 'params-header-tabs-item--is-active': activeTab === tab.value }
          ]"
          @click="updateActiveTab(tab.value)"
        >
          <p>{{ tab.label }}</p>
        </div>
      </div>
      <div class="multiview-list-single-event-params-container">
        <GameParamNumberInput
          v-for="param in activeTabParams"
          :key="param.key"
          :param="param"
          @paramChange="onGlobalParamChange($event, param)"
          @paramIncrease="onGlobalParamIncrease"
          @paramDecrease="onGlobalParamDecrease"
        />
      </div>
      <div class="params-footer">
        <Tooltip
          text="Game parameters"
          bottom
        >
          <Button
            :icon="'sliders'"
            class="small-button"
            @click="openGameParamsDrawer"
          />
        </Tooltip>
        <Tooltip
          :text="event.showSimulatedMarkets ? 'Hide simulated prices' : 'Preview simulated prices'"
          bottom
        >
          <Button
            :icon="'eye'"
            class="small-button"
            :disabled="isPreviewPricesDisabled"
            :class="{ 'preview-active': event.showSimulatedMarkets }"
            @click="showSimulatePreviewPrices"
          />
        </Tooltip>
        <Button
          :icon="'modem'"
          @click="onSimulate"
          :disabled="isSimulateDisabled"
        >
          Simulate
        </Button>
        <Button
          :icon="'arrow-up'"
          @click="onSubmit"
          :disabled="isSubmitDisabled"
        >
          Publish
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, ref } from 'vue';
import { useStore } from 'vuex';
import {
  filter, includes, cloneDeep, find, capitalize,
} from 'lodash';
import {
  getGlobalParamsKeysBySport, isBaseballGameParamsError, isBaseballGameParamsChanged,
  getBasketballGlobalParamsModelType, getTeamParamsKeysByTeamAndSport,
} from '@/components/player-setup/params-helper';
import Tooltip from '@/components/common/Tooltip';
import Button from '@/components/common/Button';
import Spinner from '@/components/common/Spinner';
import GameParamNumberInput from '@/components/player-setup/common/GameParamNumberInput';
import sportIds from '@/services/helpers/sports';
import {
  parseEventModel,
} from '@/services/helpers/event-drawer';

const {
  BASEBALL_ID,
  FOOTBALL_ID,
} = sportIds;

export default {
  components: {
    Tooltip,
    Button,
    Spinner,
    GameParamNumberInput,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const gameParams = computed(() => props?.event?.params?.gameParams);
    const openDrawer = inject('openDrawer');
    const globalParamsModelType = computed(() => getBasketballGlobalParamsModelType(parseEventModel(props.event)?.competitionType));
    const globalParamsKeys = computed(() => getGlobalParamsKeysBySport(props.event.sportId, globalParamsModelType.value));
    const globalParams = computed(() => (props.event?.sportId === BASEBALL_ID
      ? gameParams.value
      : filter(
        gameParams.value, (param) => includes(globalParamsKeys.value, param.key) && param.showOnMultiviewList,
      )));
    const teamParams = computed(() => {
      const { isUSAView, home, away } = parseEventModel(props.event);

      const teamA = isUSAView ? away : home;
      const teamB = isUSAView ? home : away;

      const teamAParamsKeys = getTeamParamsKeysByTeamAndSport(capitalize(teamA?.side), props.event.sportId, globalParamsModelType.value);
      const teamBParamsKeys = getTeamParamsKeysByTeamAndSport(capitalize(teamB?.side), props.event.sportId, globalParamsModelType.value);

      let teamAParams = [];
      let teamBParams = [];

      if (props.event.sportId === BASEBALL_ID) {
        // Team A
        const pitcherAdjustements = gameParams.value?.pitcherAdjustments;
        const pitcherAdjustementsStartingTeamA = (teamA?.side === 'HOME' ? pitcherAdjustements?.homeStarting : pitcherAdjustements?.awayStarting);
        const pitcherAdjustementsReliefTeamA = (teamA?.side === 'HOME' ? pitcherAdjustements?.homeRelief : pitcherAdjustements?.awayRelief);
        const uncertaintyTeamA = (teamA?.side === 'HOME' ? gameParams.value?.uncertainty?.home : gameParams.value?.uncertainty?.away);
        teamAParams.push((pitcherAdjustementsStartingTeamA || {}), (pitcherAdjustementsReliefTeamA || {}), (uncertaintyTeamA || {}));

        // Team B
        const pitcherAdjustementsStartingTeamB = (teamB?.side === 'HOME' ? pitcherAdjustements?.homeStarting : pitcherAdjustements?.awayStarting);
        const pitcherAdjustementsReliefTeamB = (teamB?.side === 'HOME' ? pitcherAdjustements?.homeRelief : pitcherAdjustements?.awayRelief);
        const uncertaintyTeamB = (teamB?.side === 'HOME' ? gameParams.value?.uncertainty?.home : gameParams.value?.uncertainty?.away);

        teamBParams.push((pitcherAdjustementsStartingTeamB || {}), (pitcherAdjustementsReliefTeamB || {}), (uncertaintyTeamB || {}));
      } else {
        teamAParams = filter(gameParams.value, (param) => includes(teamAParamsKeys, param.key) && param.showOnMultiviewList);
        teamBParams = filter(gameParams.value, (param) => includes(teamBParamsKeys, param.key) && param.showOnMultiviewList);
      }

      return {
        teamA: {
          name: teamA?.name,
          params: teamAParams,
        },
        teamB: {
          name: teamB?.name,
          params: teamBParams,
        },
      };
    });
    const parametersTabs = computed(() => {
      let tabs = [];
      if (props.event?.sportId === FOOTBALL_ID) {
        tabs = [
          {
            label: 'Game',
            value: 'game',
          },
        ];
      }
      tabs = [
        ...tabs,
        {
          label: teamParams.value.teamA.name,
          value: 'teamA',
        },
        {
          label: teamParams.value.teamB.name,
          value: 'teamB',
        },
      ];
      return tabs;
    });
    const activeTab = ref(parametersTabs.value[0].value);
    const updateActiveTab = (tab) => {
      activeTab.value = tab;
    };
    const activeTabParams = computed(() => {
      if (activeTab.value === 'game') {
        return globalParams.value;
      }
      if (activeTab.value === 'teamA') {
        return teamParams.value.teamA.params;
      }
      if (activeTab.value === 'teamB') {
        return teamParams.value.teamB.params;
      }
      return [];
    });
    const isSimulateDisabled = computed(() => !props?.event?.params?.isSimulateEnabled);
    const isSubmitDisabled = computed(() => (props.event?.sportId === BASEBALL_ID
      ? !isBaseballGameParamsChanged(gameParams.value) || isBaseballGameParamsError(gameParams.value)
      : !find(gameParams.value, (param) => param.value !== param.originalValue)));
    const isSimulatingPrices = computed(() => props?.event?.params?.isSimulatingPrices);
    const isParamsLoading = computed(() => store.getters.multiviewListParamsLoading);
    const isPreviewPricesDisabled = computed(() => !props.event?.simulatedMarkets?.length);
    const isSimulatedMarketsActive = computed(() => props.event?.showSimulatedMarkets);
    const updateGameParams = (updatedParam) => {
      const action = props.event?.sportId === BASEBALL_ID ? 'updateMultiviewListBaseballGameParams' : 'updateMultiviewListGameParams';
      store.dispatch(action, {
        eventId: props.event.eventId,
        updatedParam,
      });
    };
    const isCalculatedMode = computed(() => props.event?.params?.selectedParamSource === 'CALCULATED');

    const onGlobalParamChange = (value, param) => {
      const updatedParam = cloneDeep(param);
      if (param.type === 'boolean' || param.type === 'select') {
        updatedParam.value = value;
        updateGameParams(updatedParam);
        return;
      }
      if (value) {
        const paramValue = parseFloat(value);
        const isError = paramValue < param?.minValue || paramValue > param?.maxValue;
        updatedParam.value = paramValue;
        updatedParam.isError = isError;
      } else {
        updatedParam.isError = true;
        updatedParam.value = null;
      }
      updateGameParams(updatedParam);
    };

    const onGlobalParamIncrease = (param) => {
      const updatedParam = cloneDeep(param);
      const updatedValue = parseFloat((updatedParam.value + updatedParam.raiseIncrement).toFixed(param.numberOfDecimals || 3));
      if (updatedValue <= updatedParam.maxValue) {
        updatedParam.value = updatedValue;
        updateGameParams(updatedParam);
      }
    };

    const onGlobalParamDecrease = (param) => {
      const updatedParam = cloneDeep(param);
      const updatedValue = parseFloat((updatedParam.value - updatedParam.raiseIncrement).toFixed(param.numberOfDecimals || 3));
      if (updatedValue >= updatedParam.minValue) {
        updatedParam.value = updatedValue;
        updateGameParams(updatedParam);
      }
    };

    const onSimulate = () => {
      store.dispatch('simulateMultiviewGameParams', { eventId: props.event.eventId, isListSimulate: true });
    };

    const onSubmit = () => {
      store.dispatch('submitMultiviewGameParams', { eventId: props.event.eventId });
    };

    const openGameParamsDrawer = () => openDrawer(props.event.eventId, 'game_params');

    const showSimulatePreviewPrices = () => {
      store.dispatch('toggleShowSimulatePreviewPrices', { eventId: props.event.eventId });
    };

    return {
      globalParams,
      onGlobalParamChange,
      onGlobalParamIncrease,
      onGlobalParamDecrease,
      onSimulate,
      onSubmit,
      openGameParamsDrawer,
      isSimulateDisabled,
      isSubmitDisabled,
      isSimulatingPrices,
      isParamsLoading,
      showSimulatePreviewPrices,
      isPreviewPricesDisabled,
      isSimulatedMarketsActive,
      BASEBALL_ID,
      isCalculatedMode,
      activeTab,
      parametersTabs,
      updateActiveTab,
      activeTabParams,
    };
  },
};
</script>

<style lang="scss">
.parameters-container {
  flex: 1;
  width: 100%;
  height: 100%;
  border-right: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
  position: relative;

  .simulating-prices-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $gray300;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 200;
  }
  .no-params-root {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray700;
  }
  .params-header-tabs {
    display: flex;
    background: var(--neutral-bg-default-active, #FFF);

    &-item {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 32px;
      padding: 0 8px;
      gap: 4px;
      user-select: none;
      cursor: pointer;
      border-bottom: 2px solid var(--primary-stroke-weak-active, #F0F0F0);
      min-width: 0;

      &--is-active {
        border-bottom: 2px solid var(--primary-stroke-weak-active, #003C3C);
      }

      .icon {
        stroke: #191414;
      }
      p {
        width: 100%;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
      }
    }
  }
  .params-content {
    height: 100%;
    .multiview-list-single-event-params-container {
      height: calc(100% - 40px - 32px);
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      overflow: hidden;
      padding: 16px 8px;
      .game-param-number-input {
        width: calc(33.33% - 11px);
      }
    }
    .params-footer {
      display: flex;
      height: 40px;
      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border: none;
        border-left: 1px solid $gray400;
        border-radius: 0;
        &:nth-child(1) {
          border-left: none;
        }
        .icon svg path {
          fill: $black;
          stroke: none;
        }
        &.button--disabled {
          background-color: $gray300;
          .icon svg path {
            fill: $gray700;
          }
        }
        &:focus {
          box-shadow: none;
        }
        &.preview-active {
          background-color: $brandPrimary500;
          .icon svg path {
            fill: $white;
            stroke: none;
          }
        }
      }
      .small-button {
        .icon {
          margin: 0;
        }
      }
    }
  }
}
</style>
